<template>
  <div class="index">
    <div class="z_sousuo">
      <div class="h1">课题申报指南信息平台</div>
      <div class="z_sea">
        <div class="sea">
          <el-input
            placeholder="请输入关键词"
            prefix-icon="el-icon-search"
            class="wz"
            v-model="z_seawz"
            @keyup.enter.native="tozhinan1"
          >
          </el-input>
          <el-button class="dj" @click="tozhinan1">搜索</el-button>
        </div>
        <div class="resou">
          <div class="font">热搜：</div>
          <div class="a" v-for="(item, index) in rslist" :key="index" @click="tozhinan2(index)">
            {{ item.word }}
          </div>
        </div>
      </div>
    </div>
    <div class="z_weixin">
      <div class="left">
        <div class="a">
          <img src="@/components/template/img/wx1.png" /><span>微信小程序</span>
          <img
              src="@/components/template/img/xcx.png"
              alt=""
              class="ewm"
            >
        </div>
        <div class="a">
          <img src="@/components/template/img/wx2.png" /><span>微信公众号</span>
          <img
              src="@/components/template/img/gzh.png"
              alt=""
              class="ewm"
            >
        </div>
        <router-link :to="{ path: 'mySubscription' }" class="a">
          <img src="@/components/template/img/wx3.png" /><span>个性订阅</span>
        </router-link>
      </div>
      <div class="right">
        <div
          class="a"
          v-for="(item, index) in wxlist"
          :key="index"
          @click="tozhinan(index)"
        >
          <img :src="item.src" /><span>{{ item.title }}</span>
        </div>
      </div>
    </div>
    <div class="main" style="padding-top: 15px">
      <div class="content-item new-project">
        <div class="z_wenzhang1">
          <div class="left">
            <div class="header z_head">
              <div class="header-left">
                <div class="vertical-line"></div>
                <div>热门课题申报信息</div>
              </div>
              <div class="right1">
                <div
                  :class="index == current ? 'a active' : 'a'"
                  v-for="(item, index) in rmnav"
                  :key="index"
                  :id="item.id"
                  @click="check(index)"
                  :title="item.title"
                >
                  {{ item.title }}
                </div>
              </div>
            </div>
            <ul class="z_shenbao">
              <li
                v-for="(item, n) in ReportingGuidelinesList1.slice(0, 5)"
                :key="item.id"
                @click="toReportingGuideDetail(item)"
              >
                <div class="a">{{ n + 1 }}</div>
                <div class="a">{{ item.PROJECT_NAME }}</div>
                <div class="a">
                  <span v-if="item.PROJECT_GOVERNMENT === false">——</span>
                  <span v-else>{{ item.PROJECT_GOVERNMENT }}</span>
                </div>
                <div class="a">
                  <span v-if="item.PROJECT_FUNDS === null">——</span>
                  <span v-else
                    >{{ item.PROJECT_FUNDS }}
                    <span v-if="item.PROJECT_FUNDS !== null">万</span></span
                  >
                </div>
              </li>
            </ul>
          </div>
          <div class="right">
            <div class="header z_head">
              <div class="header-left">
                <div class="vertical-line"></div>
                <div>最新深度文章</div>
              </div>
            </div>
            <ul class="z_shendu">
              <li
                v-for="item in depthArticlelist.slice(0, 4)"
                :key="item.id"
                @click="depthOfTheArticleDetails(item.id)"
              >
                <div class="img">
                  <img :src="'https://keyanpro.com/upload/' + item.thumbnail" />
                </div>
                <div class="dt">
                  <div class="title">{{ item.post_title }}</div>
                  <div class="time">{{ item.dateformate }}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="z_wenzhang2">
          <div class="left">
            <div class="header z_head">
              <div class="header-left">
                <div class="vertical-line"></div>
                <div>最新指南</div>
              </div>
            </div>
            <div class="z_nav">
              <div
                :class="index == current1 ? 'a active' : 'a'"
                v-for="(item, index) in rmnav1"
                :id="item.INDUSTRY_ID"
                :key="index"
                @click="check1(index)"
              >
                {{ item.INDUSTRY_NAME }}
              </div>
            </div>
          </div>
          <div class="right">
            <ul class="z_zhinan">
              <li
                v-for="item in ReportingGuidelinesList2.slice(0, 8)"
                :key="item.id"
                @click="toReportingGuideDetail(item)"
              >
                <div class="h1">{{ item.PROJECT_NAME }}</div>
                <div class="h2">
                  <span v-if="item.PROJECT_FUNDS === null">——</span>
                  <span v-else
                    >{{ item.PROJECT_FUNDS }}
                    <span v-if="item.PROJECT_FUNDS !== null">万</span></span
                  >
                </div>
              </li>
            </ul>
          </div>
          <div class="more" @click="toreportingGuidelines">
            全部指南（<span>{{ reportingGuidelinestotal1 }}条</span>）>>
          </div>
        </div>
      </div>
      <!-- <div class="content-item new-tender">
        <div class="header">
          <div class="header-left">
            <div class="vertical-line"></div>
            <div>最新政采招标信息</div>
          </div>
          <div class="header-right" @click="topoliticsInTheTender">
            更多（<span>{{ politicsInTheTendertotal }}条</span>）>>
          </div>
        </div>
        <div class="content-main">
          <table>
            <thead>
              <tr>
                <td>状态</td>
                <td>项目名称</td>
                <td>发布机构</td>
                <td>主要方向</td>
                <td>所在省市</td>
                <td>预算金额</td>
                <td>截止时间</td>
                <td>项目详情</td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in PoliticsInTheTenderList.slice(0, 5)"
                :key="item.id"
              >
                <td v-if="item.TENDER_END_TIME >= item.TENDER_START_TIME">
                  申报中
                </td>
                <td class="asfo" v-else></td>
                <td :title="item.TENDER_NAME" @click="toNewTenderDetail(item)">
                  {{ item.TENDER_NAME }}
                </td>
                <td :title="item.TENDER_ORGANIZATION">
                  {{ item.TENDER_ORGANIZATION }}
                </td>
                <td :title="item.TENDER_KEYWORDS">
                  {{ item.TENDER_KEYWORDS }}
                </td>
                <td>
                  <span v-if="item.TENDER_PROVINCE === null">——</span
                  ><span v-else>{{ item.TENDER_PROVINCE }}</span>
                </td>
                <td>
                  <span v-if="item.TENDER_MONEY === null">——</span>
                  <span v-else
                    >{{ item.TENDER_MONEY
                    }}<span v-if="item.TENDER_MONEY !== null">万</span></span
                  >
                </td>
                <td>{{ item.dateformateend }}</td>
                <td>
                  <div class="button-see" @click="toNewTenderDetail(item)">
                    查看
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->
      <div class="banner-long-figure">
        <template>
          <el-carousel :interval="5000" arrow="always" :autoplay="false">
            <el-carousel-item
              v-for="item in advertisingLIstanbul"
              :key="item.id"
            >
              <div class="bunner-item">
                <img :src="item.imgsrc" alt="" />
                <div class="home-bottom-bunner-content">
                  <div class="bunner-content-title">{{ item.name }}</div>
                  <!-- <div class="bunner-content-main">
                  <div class="bunner-main-item">• 指南信息全文阅读</div>
                  <div class="bunner-main-item"> • 课题信息全文检索</div>
                  <div class="bunner-main-item"> • 自定义关键词订阅 </div>
                  <div class="bunner-main-item">• 机构动态订阅 </div>
                  <div class="bunner-main-item">• 招标政府信息全文阅读</div> -->
                  <!-- </div> -->
                  <div
                    class="bunner-content-button"
                    @click="toadvertising(item)"
                  >
                    查看详情
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </template>
      </div>
      <div class="content-item global-research">
        <div class="z_wenzhang3">
          <div class="right">
            <ul class="z_zhinan1">
              <li
                v-for="item in ReportingGuidelinesList3.slice(0, 6)"
                :key="item.id"
                @click="toNewTenderDetail(item)"
              >
                <div class="h1">{{ item.PROJECT_NAME }}</div>
                <div class="h2">
                  <span v-if="item.PROJECT_FUNDS === null">——</span>
                  <span v-else
                    >{{ item.PROJECT_FUNDS
                    }}<span v-if="item.PROJECT_FUNDS !== null">万</span></span
                  >
                </div>
              </li>
            </ul>
          </div>
          <div class="left">
            <div class="header z_head">
              <div class="header-left">
                <div class="vertical-line"></div>
                <div>热门专题</div>
              </div>
            </div>
            <div class="z_nav1">
              <div
                :class="index == current2 ? 'a active' : 'a'"
                v-for="(item, index) in rmnav2"
                :key="index"
                @click="check2(index)"
              >
                {{ item.topic }}
              </div>
            </div>
          </div>
        </div>

        <div class="z_wenzhang4">
          <div class="li">
            <div class="header z_head">
              <div class="header-left">
                <div class="vertical-line"></div>
                <div>同行在看</div>
              </div>
              <div class="sxright" @click="huanyipi1">
                <img src="@/components/template/img/sx.png" />换一批
              </div>
            </div>
            <ul class="z_zhinan2">
              <li
                v-for="item in ScientificResearchProjectLIstanbul1.slice(huanyipi3, huanyipi4)"
                :key="item.id"
                @click="toReportingGuideDetail(item)"
              >
               <!-- @click="toGlobalResearchDetail(item)" -->
                <div class="h1" :title="item.PROJECT_NAME">{{ item.PROJECT_NAME }}</div>
                <div class="h2">
                  <span v-if="item.PROJECT_FUNDS === null">——</span>
                  <span v-else
                    >{{ item.PROJECT_FUNDS }}{{ item.PROJECT_FUNDS_UNIT }}</span
                  >
                  <span v-if="item.PROJECT_FUNDS !== null">万</span>
                </div>
              </li>
            </ul>
          </div>

          <div class="li">
            <div class="header z_head">
              <div class="header-left">
                <div class="vertical-line"></div>
                <div>即将截止</div>
              </div>
              <div class="sxright" @click="huanyipi2">
                <img src="@/components/template/img/sx.png" />换一批
              </div>
            </div>
            <ul class="z_zhinan2">
              <li
                v-for="item in ScientificResearchProjectLIstanbul2.slice(huanyipi5, huanyipi6)"
                :key="item.id"
                @click="toReportingGuideDetail(item)"
              >
                <div class="h1" :title="item.PROJECT_NAME">{{ item.PROJECT_NAME }}</div>
                <div class="h2">
                  <span v-if="item.PROJECT_FUNDS === null">——</span>
                  <span v-else
                    >{{ item.PROJECT_FUNDS }}{{ item.PROJECT_FUNDS_UNIT }}</span
                  >
                  <span v-if="item.PROJECT_FUNDS !== null">万</span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- <div class="header">
          <div class="header-left">
            <div class="vertical-line"></div>
            <div>全球科研项目信息</div>
          </div>
          <div class="header-right" @click="toscientificResearchProject">
            更多（<span>{{ scientificResearchProjecttotal }}条</span>）>>
          </div>
        </div>
        <div class="content-main">
          <table>
            <thead>
              <tr>
                <td>项目名称</td>
                <td>资助机构</td>
                <td>承担机构</td>
                <td>项目金额</td>
                <td>项目周期</td>
                <td>项目详情</td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in ScientificResearchProjectLIstanbul.slice(0, 5)"
                :key="item.id"
              >
                <td
                  :title="item.PROJECT_NAME"
                  @click="toGlobalResearchDetail(item)"
                >
                  {{ item.PROJECT_NAME }}
                </td>
                <td :title="item.PROJECT_ORGANIZATION_FUND">
                  {{ item.PROJECT_ORGANIZATION_FUND }}
                  <span v-if="item.PROJECT_ORGANIZATION_FUND === null">——</span>
                </td>
                <td :title="item.PROJECT_ORGANIZATION">
                  {{ item.PROJECT_ORGANIZATION }}
                  <span v-if="item.PROJECT_ORGANIZATION === null">——</span>
                </td>
                <td :title="item.PROJECT_FUNDS + '(美元)'">
                  <span v-if="item.PROJECT_FUNDS === null">——</span>
                  <span v-else
                    >{{ item.PROJECT_FUNDS }}{{ item.PROJECT_FUNDS_UNIT }}</span
                  >
                  <span v-if="item.PROJECT_FUNDS !== null">(美元)</span>
                </td>
                <td>
                  <span v-if="item.dateformatestart == null">——</span>
                  <span v-else
                    >{{ item.dateformatestart }}
                    <span v-if="item.dateformatestart">-</span
                    >{{ item.dateformateend }}</span
                  >
                </td>
                <td>
                  <div @click="toGlobalResearchDetail(item)">查看</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div> -->
        <vip></vip>
      </div>
    </div>
  </div>
</template>

<script>
import vip from "../vip/vip.vue";
import {
  getDepthArticlelist,
  getReportingGuidelinesList,
  getPoliticsInTheTenderList,
  getPoliticsInTheTenderList2,
  getScientificResearchProjectList,
  getScientificResearchProjectCountt,
  getAdvertisinglist,
  getHsjword,
  getHsjindustry_new,
  getHsjtopics,
} from "../../../api/homePage";
import { formatDate } from "../../../util/tools";
import {
  Addhistoricalrecord,
  Addhistoricalrecordzc,
  Addhistoricalrecordky,
  Addhistoricalrecordwz,
} from "../../../api/myRecentInformation";
export default {
  data() {
    return {
      // 深度文章列表
      depthArticlelist: [],
      // 课题申报列表
      ReportingGuidelinesList: [],
      ReportingGuidelinesList1: [],
      ReportingGuidelinesList2: [],
      ReportingGuidelinesList3: [],
      // 政商招标列表
      PoliticsInTheTenderList: [],
      // 科研项目列表
      ScientificResearchProjectLIstanbul: [],
      ScientificResearchProjectLIstanbul1: [],
      ScientificResearchProjectLIstanbul2: [],
      // 广告列表
      advertisingLIstanbul: [],
      // 课题申报总量
      reportingGuidelinestotal: "0",
      reportingGuidelinestotal1: "0",
      // 最新政采招标总量
      politicsInTheTendertotal: "0",
      // 科研项目总量
      scientificResearchProjecttotal: "0",
      //搜索
      z_seawz: "",
      //热搜
      rslist: [],
      //微信导航
      wxlist: [
        {
          src: require("@/components/template/img/wx4.png"),
          title: "自科基金",
        },
        {
          src: require("@/components/template/img/wx5.png"),
          title: "社科项目",
        },
        {
          src: require("@/components/template/img/wx6.png"),
          title: "部委项目",
        },
        {
          src: require("@/components/template/img/wx7.png"),
          title: "开放课题",
        },
        {
          src: require("@/components/template/img/wx8.png"),
          title: "地方课题",
        },
        {
          src: require("@/components/template/img/wx9.png"),
          title: "扶持奖励",
        },
        {
          src: require("@/components/template/img/wx10.png"),
          title: "人才项目",
        },
      ],
      rmnav: [
        { title: "24小时", id: 1 },
        { title: "一周", id: 2 },
        { title: "一月", id: 3 },
      ],
      period_type: 1,
      current: 0,
      rmnav1: [],
      current1: 0,
      rmnav2: [],
      rmzt: "元宇宙",
      current2: 0,
      channel:0,
      huanyipi3:0,
      huanyipi4:5,
      huanyipi5:0,
      huanyipi6:5
    };
  },
  components: {
    vip,
  },
  beforeCreate() {
    if (this.$route.query.login) {
      const newQuery = JSON.parse(JSON.stringify(this.$route.query)); // 深拷贝
      delete newQuery.login;
      // 如果有引入 lodash, 可以写成: let newQuery = _.omit(this.$route.query, 'code')
      this.$router.replace({ query: newQuery });
      this.$router.go(0);
    }
  },
  mounted(){
    window.scrollTo(0,0)
  },
  created() {
    // 获取初始化数据
    this.getData();
    this.getHotProject();
    this.getNewestProjectList();
    this.getHotTopic();
    this.getPeerReview();
    this.getCutOff();
    this.navxuanzhong()
  },
  methods: {
    huanyipi1(){
      let huanshuliang = this.$data.ScientificResearchProjectLIstanbul1.length
      if(this.huanyipi4 >= huanshuliang){
        this.huanyipi3 = 0
        this.huanyipi4 = 5
      } else {
        this.huanyipi3 = this.huanyipi3 + 5
        this.huanyipi4 = this.huanyipi4 + 5
      }
      // this.getPeerReview();
    },
    huanyipi2(){
       let huanshuliang = this.$data.ScientificResearchProjectLIstanbul2.length
      if(this.huanyipi6 >= huanshuliang){
        this.huanyipi5 = 0
        this.huanyipi6 = 5
      } else {
        this.huanyipi5 = this.huanyipi5 + 5
        this.huanyipi6 = this.huanyipi6 + 5
      }
      // this.getCutOff();
    },
    navxuanzhong(){
      localStorage.setItem("navActive", 1);
      this.$store.commit("setnavActive", 1);
    },
    // 格式化日期格式
    formateDateList(arr) {
      arr.forEach((element) => {
        // eslint-disable-next-line no-prototype-builtins
        if (element.hasOwnProperty("create_time")) {
          element.dateformate = formatDate(element.create_time);
          // eslint-disable-next-line no-prototype-builtins
        } else if (element.hasOwnProperty("PROJECT_DATE")) {
          element.dateformatestart = formatDate(parseInt(element.PROJECT_DATE))
            .trim()
            .replace(/-/g, "/");
          element.dateformateend = formatDate(
            parseInt(element.PROJECT_DATE_END)
          )
            .trim()
            .replace(/-/g, "/");
          // eslint-disable-next-line no-prototype-builtins
        } else if (element.hasOwnProperty("TENDER_START_TIME")) {
          // element.endTimeStamp = Date.parse(element.TENDER_END_TIME)
          if (element.TENDER_END_TIME === false) {
            element.dateformateend = "——";
          } else {
            element.dateformateend = formatDate(
              parseInt(element.TENDER_END_TIME)
            )
              .trim()
              .replace(/-/g, "/");
          }

          element.dateformatestart = formatDate(
            parseInt(element.TENDER_START_TIME)
          )
            .trim()
            .replace(/-/g, "/");
          // eslint-disable-next-line no-prototype-builtins
        } else if (element.hasOwnProperty("PROJECT_START")) {
          if (element.PROJECT_START === false) {
          } else {
            element.dateformatestart = formatDate(
              parseInt(element.PROJECT_START)
            )
              .trim()
              .replace(/-/g, "/")
              .slice(0, 4);
            element.dateformateend = formatDate(parseInt(element.PROJECT_END))
              .trim()
              .replace(/-/g, "/")
              .slice(0, 4);
          }
        }
      });
    },

    // 点击跳转
    // 跳转到更多深度文章
    moreDepthArticle(id) {
      localStorage.setItem("navActive", 5);
      this.$store.commit("setnavActive", 5);
      const tid = id;
      const uid = this.$store.state.userid;
      const params = {
        title_id: tid,
        user_id: uid,
        // type: 1
      };
      Addhistoricalrecordwz(params).then(
        (res) => {
          console.log("添加历史记录成功", res);
        },
        (err) => {
          console.log("添加历史记录异常", err);
        }
      );
      this.$router.push({
        path: "depthOfTheArticle",
      });
      window._czc.push(["_trackEvent", "点击", "跳转到更多深度文章"]);
      console.log("czc", window._czc);
    },
    // 跳转到更多文章详情页
    depthOfTheArticleDetails(id) {
      localStorage.setItem("navActive", 5);
      this.$store.commit("setnavActive", 5);
      const tid = id;
      const uid = this.$store.state.userid;
      const params = {
        title_id: tid,
        user_id: uid,
        // type: 1
      };
      Addhistoricalrecordwz(params).then(
        (res) => {
          console.log("添加历史记录成功", res);
        },
        (err) => {
          console.log("添加历史记录异常", err);
        }
      );
      this.$router.push({
        path: `depthOfTheArticleDetails${id}`,
      });
      window._czc.push(["_trackEvent", "点击", "跳转到更多文章详情页"]);
      console.log("czc", window._czc);
    },
    // 跳转最新指南
    tozhinan(index) {
      this.$store.commit("setketileixing", index + 1);
      // this.$store.commit("setserach111", this.wxlist[index].title);
      this.$router.push({
        path: "reportingGuidelines",
      });
    },
    tozhinan1() {
      this.$store.commit("setserach111", this.z_seawz);
      this.$router.push({
        path: "reportingGuidelines",
      });
    },
    tozhinan2(index) {
      this.$store.commit("setserach111", this.rslist[index].word);
      this.$router.push({
        path: "reportingGuidelines",
      });
    },
    // 跳转到课题申报列表页
    toreportingGuidelines() {
      localStorage.setItem("navActive", 2);
      this.$store.commit("setnavActive", 2);
      this.$router.push({
        path: "reportingGuidelines",
      });
      window._czc.push(["_trackEvent", "点击", "跳转到课题申报列表页"]);
    },
    // 跳转到政采招标列表页
    topoliticsInTheTender() {
      localStorage.setItem("navActive", 3);
      this.$store.commit("setnavActive", 3);
      this.$router.push({
        path: "politicsInTheTender",
      });
      window._czc.push(["_trackEvent", "点击", "跳转到政采招标列表页"]);
    },
    // 跳转到全球科研项目列表页
    toscientificResearchProject() {
      localStorage.setItem("navActive", 4);
      this.$store.commit("setnavActive", 4);
      this.$router.push({
        path: "scientificResearchProject",
      });
      window._czc.push(["_trackEvent", "点击", "跳转到全球科研项目列表页"]);
    },
    // 跳转到全球科研项目详情页
    toGlobalResearchDetail(item) {
      localStorage.setItem("navActive", 4);
      this.$store.commit("setnavActive", 4);
      const id = item.PROJECT_ID;
      const uid = this.$store.state.userid;
      const params = {
        title_id: id,
        user_id: uid,
        // type: 1
      };
      Addhistoricalrecordky(params).then(
        (res) => {
          console.log("添加历史记录成功", res);
        },
        (err) => {
          console.log("添加历史记录异常", err);
        }
      );
      let md = item.PROJECT_MD5;
      if (md === null) {
        md = "";
      }
      this.$router.push({
        name: "scientificResearchProjectDetails",
        params: {
          id: id,
        },
        query: {
          PROJECT_GOV_MD5: md,
        },
      });
      window._czc.push(["_trackEvent", "点击", "跳转到科研项目详情页"]);
    },
    // 跳转到最新课题申报信息详情页
    toReportingGuideDetail(item) {
      localStorage.setItem("navActive", 2);
      this.$store.commit("setnavActive", 2);
      const id = item.IN_PROJECT_GOV_ID;
      const uid = this.$store.state.userid;
      const params = {
        title_id: id,
        user_id: uid,
        // type: 1
      };
      Addhistoricalrecord(params).then(
        (res) => {
          console.log("添加历史记录成功", res);
        },
        (err) => {
          console.log("添加历史记录异常", err);
        }
      );
      let md = item.PROJECT_GOV_MD5;
      if (md === null) {
        md = "";
      }
      // debugger
      this.$router.push({
        name: "reportingGuidelinesDetails",
        params: {
          id: id,
        },
        query: {
          PROJECT_GOV_MD5: md,
        },
      });
      window._czc.push(["_trackEvent", "点击", "跳转到申报指南详情页"]);
    },
    // 跳转到最新政采招标信息详情页
      toNewTenderDetail(item) {
      localStorage.setItem("navActive", 2);
      this.$store.commit("setnavActive", 2);
      const id = item.IN_PROJECT_GOV_ID;
      const uid = this.$store.state.userid;
      const params = {
        title_id: id,
        user_id: uid,
        // type: 1
      };
      Addhistoricalrecordzc(params).then(
        (res) => {
          console.log("添加历史记录成功", res);
        },
        (err) => {
          console.log("添加历史记录异常", err);
        }
      );
      let md = item.PROJECT_GOV_MD5;
      if (md === null) {
        md = "";
      }
      this.$router.push({
        // name: "politicsInTheTenderDetails",
        name: "reportingGuidelinesDetails",
        params: {
          id: id,
        },
        query: {
          TENDER_MD5: md,
        },
      });
    },
    // 点击跳转到广告详情页面
    toadvertising(item) {
      // console.log(item.url);
      // const url = `https://${item.url}`;
      // window.open(url);
      // window._czc.push(["_trackEvent", "点击", "跳转到广告详情页面"]);
      this.$router.push({
        path: "/pc/viproot/vip",
      });
    },
    // 点击查看投资力度
    // showMoney (event) {
    //   event.target.classList.add('hide')
    //   event.currentTarget.nextElementSibling.classList.remove('hide')
    //   event.currentTarget.nextElementSibling.classList.add('show')
    // },
    // 点击隐藏投资力度
    // hiddeMoney (event) {
    //   console.dir(event.target)
    //   event.target.classList.add('hide')
    //   event.currentTarget.previousElementSibling.classList.remove('hide')
    //   event.currentTarget.previousElementSibling.classList.add('show')
    // },
    // 深度文章数据处理
    // 返回数据处理
    processingReturnData(arr) {
      arr.forEach((elem) => {
        if (elem.photo) {
          elem.imgsrc = `https://keyanpro.com/upload/${elem.photo}`;
        } else {
          elem.imgsrc = `https://keyanpro.com/upload/${elem.thumbnail}`;
          elem.tagsArray = elem.post_keywords.split(";");
        }
      });
      return arr;
    },
    //热门课题
    getHotProject() {
      const that = this;
      const axios = require("axios");
      const formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      const appurl = "https://data.keyanpro.com/keyanpro3/";
      formData.append("period_type", that.period_type); //添加文件对象 ，data中设置的
      axios({
        url: appurl + "HotProject",
        method: "post",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (res) {
          console.log(res);
          that.$data.ReportingGuidelinesList1 = res.data.resultList;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //热门课题
    getNewestProjectList() {
      const that = this;
      const axios = require("axios");
      const formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      const appurl = "https://data.keyanpro.com/keyanpro3/";
      formData.append("user_id", ""); //添加文件对象 ，data中设置的
      formData.append("channel", that.channel);
      formData.append("page", 1);
      formData.append("search_word", "");
      axios({
        url: appurl + "NewestProjectList",
        method: "post",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (res) {
          console.log(res);
          that.$data.ReportingGuidelinesList2 = res.data.resultList;
          that.$data.reportingGuidelinestotal1 = res.data.numFound;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //热门专题
    getHotTopic() {
      const that = this;
      const axios = require("axios");
      const formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      const appurl = "https://data.keyanpro.com/keyanpro3/";
      formData.append("topic_id", that.rmzt); //添加文件对象 ，data中设置的
      formData.append("page", 1);
      axios({
        url: appurl + "HotTopic",
        method: "post",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (res) {
          console.log(res);
          that.$data.ReportingGuidelinesList3 = res.data.resultList;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //同行再看
    getPeerReview() {
      const that = this;
      const axios = require("axios");
      const formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      const appurl = "https://data.keyanpro.com/keyanpro3/";
      let userid = this.$store.state.userid
      if(userid == ""){
        userid = 0
      }
      formData.append("user_id", userid); //添加文件对象 ，data中设置的
      axios({
        url: appurl + "PeerReview",
        method: "post",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (res) {
          console.log(res);
          that.$data.ScientificResearchProjectLIstanbul1 = res.data.resultList;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //即将截止
    getCutOff() {
      const that = this;
      const axios = require("axios");
      const formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      const appurl = "https://data.keyanpro.com/keyanpro3/";
      let userid = this.$store.state.userid
      if(userid == ""){
        userid = 0
      }
      formData.append("user_id", userid); //添加文件对象 ，data中设置的
      formData.append("page", 1);
      axios({
        url: appurl + "CutOff",
        method: "post",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (res) {
          console.log(res);
          that.$data.ScientificResearchProjectLIstanbul2 = res.data.resultList;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 数据获取
    getData() {
      const that = this;
      const axios = require("axios");
      const formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      const appurl = "https://data.keyanpro.com/keyanpro3/";
      //热门专题
      getHsjtopics().then(
        (res) => {
          console.log("最新指南/学科门类", res);
          that.rmnav2 = res;
        },
        (err) => {
          console.log("最新指南/学科门类报错", err);
        }
      );
      //最新指南学科门类
      getHsjindustry_new().then(
        (res) => {
          console.log("最新指南/学科门类", res);
          that.rmnav1 = res;
        },
        (err) => {
          console.log("最新指南/学科门类报错", err);
        }
      );
      //
      //热搜词
      getHsjword().then(
        (res) => {
          console.log("热搜词数据", res);
          // res = this.processingReturnData(res);
          that.rslist = res;
        },
        (err) => {
          console.log("热搜词报错", err);
        }
      );
      // 获取广告列表数据
      getAdvertisinglist().then(
        (res) => {
          console.log("广告数据列表数据", res);
          res.data = this.processingReturnData(res.data);
          that.$data.advertisingLIstanbul = res.data;
          console.log("数据处理后", res.data);
        },
        (err) => {
          console.log("广告数据列表报错", err);
        }
      );
      // 获取深度文章
      getDepthArticlelist({ limit: 12, page: 1 }).then(
        (res) => {
          that.formateDateList(res.data);
          res.data = this.processingReturnData(res.data);
          that.$data.depthArticlelist = res.data;
          console.log("最新深度文章", res.data);
        },
        (err) => console.log(err)
      );
      // 获取课题申报列表
      getReportingGuidelinesList({ limit: 5, page: 1 }).then(
        (res) => {
          // 该方法会给res.data中添加dateformate字段用来存储格式化后的创建时间
          console.log("课题申报", res.data);
          that.formateDateList(res.data.data);
          that.$data.reportingGuidelinestotal = res.data.count;
          that.$data.ReportingGuidelinesList = res.data.data;
        },
        (err) => console.log(err)
      );
      // 获取政商招标信息
      getPoliticsInTheTenderList({ limit: 5, page: 1 }).then((res) => {
        that.formateDateList(res.data.data);
        that.$data.PoliticsInTheTenderList = res.data.data;
      });
      getPoliticsInTheTenderList2({ limit: 5, page: 1 }).then((res) => {
        // debugger;
        that.$data.politicsInTheTendertotal = res.data.count;
      });
      // 获取科研项目列表
      getScientificResearchProjectList({ limit: 5, page: 1 }).then((res) => {
        console.log("科研项目处理前", res);
        that.formateDateList(res.data.data);
        console.log("处理后", res.data.data);
        that.$data.ScientificResearchProjectLIstanbul = res.data.data;
        console.log("科研项目数据返回", res.data.data);
      });
      getScientificResearchProjectCountt({ limit: 5, page: 1 }).then((res) => {
        that.$data.scientificResearchProjecttotal = res.data.count;
      });
    },
    // 点击立即注册
    registnow(event) {
      event.stopPropagation();
      this.$router.push({
        path: "/pc/loginroot/registered",
      });
      window._czc.push(["_trackEvent", "点击", "点击立即注册"]);
    },

    // 热门切换
    check(index) {
      this.current = index;
      this.period_type = this.rmnav1[index].ID;
      this.getHotProject();
    },
    check1(index) {
      this.current1 = index;
      this.channel = this.rmnav1[index].INDUSTRY_ID;
      this.getNewestProjectList()
      console.log(this.channel)
    },
    check2(index) {
      this.current2 = index;
      this.rmzt = this.rmnav2[index].topic;
      this.getHotTopic();
      console.log(this.rmzt)
    },
  },
};
</script>
<style>
/* element-ui
 */
thead .el-table th,
.el-table tr {
  background-color: #f9f9f9;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.el-carousel__container {
  height: 448.88px;
}
.el-carousel__item h3 {
  height: 448.88px;
}
/* 轮播图分页器样式 */
li.el-carousel__indicator.el-carousel__indicator--horizontal
  .el-carousel__button {
  width: 18px;
  height: 5px;
  background-color: #323337;
  margin: 0 5px;
}
li.el-carousel__indicator.el-carousel__indicator--horizontal.is-active
  .el-carousel__button {
  width: 18px;
  height: 5px;
  background-color: #9502a1;
  margin: 0 5px;
}
.bunner-item {
  position: relative;
}
.top-left .el-carousel__indicators--horizontal {
  position: absolute;
  transform: translate(-369px, -11px);
}
/* 隐藏轮播图左右切换按钮 */
.el-carousel__arrow {
  opacity: 0;
}
.bunder-black {
  position: absolute;
  width: 100%;
  height: 135px;
  bottom: 0px;
  left: 0px;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0)
  );
}
.bunder-title {
  z-index: 1;
  position: absolute;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  transform: translate(38px, -94px);
}
.bunder-more {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #9d9d9d;
  transform: translate(712px, -64px);
}
/* 全局通用小样式 */
/* 竖线 */
.vertical-line {
  border-radius: 2.5px;
  width: 5px;
  height: 18px;
  background-color: #9702a3;
  margin-right: 4px;
}
/* 圆点 */
.dot {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #5e068c;
  margin-right: 8px;
}

.show {
  display: block !important;
}
.hide {
  display: none !important;
}
</style>

<style lang="scss" scoped>
.main {
  padding: 40px 0px;
  .top {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .top-left {
      width: 798px;
      height: 448.88px;
      img {
        width: 798px;
        height: 448.88px;
        object-fit: cover;
      }
    }
    .top-right {
      width: 382px;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px dashed #d1d1d1;
        padding-bottom: 8px;
        .header-left {
          color: #9a0aa6;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 28px;
          color: #5e068c;
          margin-right: 200px;
        }
        .header-right {
          cursor: pointer;
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 20px;
          color: #9d9d9d;
          opacity: 1;
        }
      }
      .content {
        cursor: pointer;
        ul {
          margin: 20px 0 0 0;
          padding: 0px;
          list-style: none;
          li {
            margin-bottom: 19px;
            transition: 0.3s;
            &:nth-last-child(1) {
              margin: 0px;
            }
            &:hover {
              background-color: #f9f9f9;
            }

            .title {
              display: flex;
              justify-content: left;
              align-items: center;
              .dot-text {
                flex: 1;
              }
              span:nth-of-type(2) {
                font-size: 15px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 20px;
                color: #070707;
                opacity: 1;
              }
            }
            .time {
              font-size: 15px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 20px;
              color: #707070;
              opacity: 1;
              padding-left: 15px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .content-item {
    width: 1200px;
    margin: 0 auto;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header-left {
        display: flex;
        justify-content: left;
        align-items: center;
        div:nth-of-type(2) {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 28px;
          color: #5e068c;
          opacity: 1;
        }
      }
      .header-right {
        cursor: pointer;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 20px;
        color: #9d9d9d;
        opacity: 1;
        span {
          color: #990263;
        }
      }
    }
    .content-main {
      table {
        cursor: pointer;
        width: 100%;
        border-spacing: 0px;
        border-collapse: collapse;
        table-layout: fixed;
        thead {
          tr {
            height: 55px;
            border-bottom: 1px solid #f0f0f0;
            td {
              box-sizing: border-box;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: bold;
              line-height: 21px;
              color: #3b3b3b;
              opacity: 1;
              background-color: #f9f9f9;
            }
            td:nth-of-type(1) {
              text-align: center;
              width: 84px;
            }
            td:nth-of-type(2) {
              width: 411px;
            }
            td:nth-of-type(3) {
              width: 168px;
            }
            td:nth-of-type(4) {
              width: 148px;
            }
            td:nth-of-type(5) {
              width: 105px;
            }
            td:nth-of-type(6) {
              width: 105px;
            }
            td:nth-of-type(7) {
              width: 89px;
            }
            td:nth-of-type(8) {
              text-align: center;
            }
          }
        }
        tbody {
          tr {
            cursor: pointer;
            height: 60px;
            border-bottom: 1px solid #f0f0f0;
            box-sizing: border-box;
            &:hover {
              background-color: #f9f9f9;
            }
            td {
              box-sizing: border-box;
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #282828;
              opacity: 1;
            }
            td[class="asfo"] {
              color: #707070 !important;
            }
            td:nth-of-type(1) {
              text-align: center;
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              line-height: 18px;
              color: #990263;
              opacity: 1;
            }
            td:nth-of-type(2) {
              font-weight: 700;
              padding-right: 6px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            td:nth-of-type(3) {
              padding-right: 6px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            td:nth-of-type(4) {
              padding-right: 6px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            td:nth-of-type(5) {
            }
            td:nth-of-type(6) {
              padding-right: 6px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            td:nth-of-type(7) {
            }
            td:nth-of-type(8) {
              text-align: center;
              .button-see {
                margin: 0 auto;
                box-sizing: border-box;
                width: 67px;
                height: 28px;
                border: 1px solid #990263;
                opacity: 1;
                border-radius: 4px;
                text-align: center;
                line-height: 28px;
                color: #990263;
              }
            }
          }
        }
      }
    }
  }
  .new-project {
    margin-bottom: 42px;
    margin-top: 0px;
    .header {
      margin-bottom: 6px;
    }
  }
  .new-tender {
    margin-bottom: 105px;
    .header {
      margin-bottom: 6px;
    }
  }
  .global-research {
    .header {
      margin-bottom: 6px;
    }
    .content-main {
      table {
        thead {
          tr {
            td {
            }
            td:nth-of-type(1) {
              width: 437px;
              text-align: left;
              padding-left: 24px;
            }
            td:nth-of-type(2) {
              width: 229px;
              text-align: left;
            }
            td:nth-of-type(3) {
              width: 229px;
              text-align: left;
            }
            td:nth-of-type(4) {
              width: 100px;
              text-align: right;
            }
            td:nth-of-type(5) {
              width: 100px;
              text-align: right;
            }
            td:nth-of-type(6) {
              padding-right: 14px;
              text-align: right;
            }
          }
        }

        tbody {
          tr {
            td {
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #282828;
            }
            td:nth-of-type(1) {
              font-weight: 700;
              padding-left: 24px;
              text-align: left;
              width: 437px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #282828;
            }
            td:nth-of-type(2) {
              font-weight: 500;
              width: 229px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #282828;
            }
            td:nth-of-type(3) {
              width: 229px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #282828;
            }
            td:nth-of-type(4) {
              text-align: right;
              width: 100px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #282828;
            }
            td:nth-of-type(5) {
              text-align: right;
            }
            td:nth-of-type(6) {
              padding-right: 10px;

              div {
                float: right;
                width: 67px;
                height: 28px;
                border: 1px solid #990263;
                opacity: 1;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 15px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #990263;
              }
            }
          }
        }
      }
    }
  }
  .banner-long-figure {
    margin: 0 auto;
    margin-bottom: 50px;
    height: 200px;
    margin-bottom: 126px;
    img {
      width: 1920px;
      height: 245px;
    }
  }
}
</style>
<style lang="scss">
.banner-long-figure {
  .el-carousel__container {
    background-color: #fff;
    overflow: inherit;
    position: relative;
    height: 247.19px;
    .bunner-item {
      background-color: #f1f2f6;
      .home-bottom-bunner-content {
        transform: translate(440px, -170px);
        .bunner-content-title {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #ffffff;
          margin-bottom: 15px;
          width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3; //（行数）
          -webkit-box-orient: vertical;
        }
        .bunner-content-main {
          font-size: 14px;
          width: 546px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          display: flex;
          justify-content: left;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 21px;
          .bunner-main-item {
            margin-right: 23px;
            margin-bottom: 10px;
          }
        }
        .bunner-content-button {
          cursor: pointer;
          width: 94px;
          height: 31px;
          background-color: #ffcb84;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #4f342e;
          position: absolute;
          bottom: -112px;
        }
      }
    }
  }
  .el-carousel__indicators--horizontal {
    transform: translate(-529px, 0px);
  }

  li.el-carousel__indicator.el-carousel__indicator--horizontal
    .el-carousel__button {
    background-color: #a17ab5;
  }
  li.el-carousel__indicator.el-carousel__indicator--horizontal.is-active
    .el-carousel__button {
    background-color: #550e5b;
  }
}
</style>


<style scoped lang="scss">
.index {
  background: #f1f2f6;
}
.z_sousuo {
  width: 100%;
  background: url("~@/components/template/img/bg.png")#000 no-repeat center;
  height: 328px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: 1920px;
  .h1 {
    font-size: 32px;
    color: #fff;
    font-weight: bold;
    margin-top: 55px;
    margin-bottom: 50px;
  }
  .z_sea {
    width: 955px;
    display: flex;
    flex-direction: column;
    .sea {
      display: flex;
      .wz {
        flex: 1;
        width: 0;
        margin-right: 5px;
        border-radius: 40px 0 0 40px;
        overflow: hidden;
        height: 50px;
        ::v-deep .el-input__inner{height: 100%;padding-left: 40px;}
        ::v-deep .el-input__icon{display: flex;align-items: center;justify-content: center;margin-left: 10px;}
      }
      .dj {
        width: 90px;
        border-radius: 0 40px 40px 0;
        background: #9702a3;
        border-color: #9702a3;
        color: #fff;
        height: 50px;
      }
    }
  }
  .resou {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-top: 15px;
    .font {
      color: #fff;
      margin-right: 10px;
    }
    .a {
      color: #dadada;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}

.z_weixin {
  width: 1200px;
  height: 100px;
  background: #ffffff;
  box-shadow: 0px 3px 12px 1px rgba(137, 137, 137, 0.16);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 9;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: -50px;
  padding: 0 35px;
  .left {
    display: flex;
    .a {
      margin-right: 45px;
      position: relative;
      .ewm{position: absolute;top: 60px;right: -40px;width: 150px;display: none;margin: 0 auto;}
      &:hover{
        .ewm{display: block;}
      }
      &:last-child {
        margin-right: 0;
        padding: 0 45px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 1px;
          height: 40px;
          background: #f0f0f0;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 40px;
          background: #f0f0f0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  .right {
    display: flex;
    flex: 1;
      width:0;
    .a {
      cursor: pointer;
      flex: 1;
      width:0;
    }
  }
  .a {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    color: #515151;
    img {
      width: 40px;
      margin-bottom: 9px;
    }
  }
}

.z_wenzhang1 {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  .left {
    flex: 1;
    width: 0;
    margin-right: 15px;
    background: #fff;
    box-sizing: border-box;
    padding: 20px 25px;
  }
  .right {
    width: 360px;
    background: #fff;
    box-sizing: border-box;
    padding: 20px 25px;
  }
}

.z_head {
  margin-bottom: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
  .right1 {
    display: flex;
    align-items: center;
    .a {
      cursor: pointer;
      font-size: 17px;
      color: #9a9a9a;
      margin-left: 20px;
      &.active {
        color: #990263;
      }
    }
  }
}
.z_shenbao {
  width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  margin-top: 30px;
  li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      .a {
        &:nth-child(2) {
          color: #990263;
        }
      }
    }
    &:nth-child(1) {
      .a {
        &:nth-child(1) {
          background: url(~@/components/template/img/1.png) no-repeat center;
        }
      }
    }
    &:nth-child(2) {
      .a {
        &:nth-child(1) {
          background: url(~@/components/template/img/2.png) no-repeat center;
        }
      }
    }
    &:nth-child(2) {
      .a {
        &:nth-child(1) {
          background: url(~@/components/template/img/3.png) no-repeat center;
        }
      }
    }
    .a {
      font-size: 16px;
      color: #000000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:nth-child(1) {
        width: 20px;
        height: 25px;
        background: url(~@/components/template/img/4.png) no-repeat center;
        font-size: 9px;
        color: #fff;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding-bottom: 5px;
      }
      &:nth-child(2) {
        width: 450px;
      }
      &:nth-child(3) {
        width: 175px;
      }
      &:nth-child(4) {
        width: 70px;
        text-align: right;
      }
    }
  }
}

.z_shendu {
  width: 100%;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  li {
    list-style-type: none;
    margin-bottom: 25px;
    cursor: pointer;
    display: flex;
    &:last-child {
      margin-bottom: 0;
    }
    .img {
      width: 65px;
      height: 65px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .dt {
      flex: 1;
      width: 0;
      margin-left: 15px;
      line-height: 1.7;
      display: flex;
      flex-direction: column;
      .title {
        font-size: 15px;
        color: #070707;
      }
      .time {
        font-size: 12px;
        color: #b6b6b6;
      }
    }
  }
}

.z_wenzhang2 {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 35px;
  position: relative;
  display: flex;
  background: #fff;
  margin-bottom: 29px;
  &::before {
    content: "";
    position: absolute;
    width: 448px;
    height: 100%;
    z-index: 1;
    background: #5e068c;
    left: 0;
    top: 0;
  }
  .left {
    width: 275px;
    position: relative;
    z-index: 2;
    .z_head {
      box-sizing: border-box;
      padding-left: 25px;
      border-bottom:1px solid rgba(255,255,255,0.3);
      .header-left {
        .vertical-line {
          background-color: #fff;
        }
        div {
          &:nth-child(2) {
            color: #fff;
          }
        }
      }
    }
  }
  .right {
    flex: 1;
    width: 0;
    height: 580px;
    background: #fff;
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    padding: 21px 31px 18px 25px;
  }
  .more {
    position: absolute;
    right: 31px;
    bottom: 18px;
    font-size: 14px;
    color: #b6b6b6;
    cursor: pointer;
    span {
      color: #990263;
    }
  }
}

.z_nav {
  width: 100%;
  height: 545px;
  overflow: auto;
  &::-webkit-scrollbar-thumb {
    background: rgba(153, 153, 153, 0.5);
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  .a {
    cursor: pointer;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 16px;
    color: #fff;
    padding-left: 35px;
    position: relative;
    &::before{content: "";position: absolute;width: calc(100% - 100px);right: 0;bottom: 0;height: 1px;background: rgba(255, 255, 255, 0.1);}
    &.active {
      background: rgba(255, 255, 255, 0.1);
    }
  }
}

.z_zhinan {
  width: 100%;
  margin: 0;
  padding: 0;
  li {
    width: 100%;
    list-style-type: none;
    height: 50px;
    font-size: 16px;
    color: #010101;
    background: #fafafc;
    display: flex;
    align-items: center;
    border-radius: 40px;
    box-sizing: border-box;
    padding-left: 28px;
    padding-right: 35px;
    margin-bottom: 20px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    .h1 {
      flex: 1;
      margin-right: 35px;
      width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .h2 {
      width: auto;
      white-space: nowrap;
    }
  }
}

.z_wenzhang3 {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 30px;
  position: relative;
  display: flex;
  background: #fff;
  margin-bottom: 29px;
  height: 480px;
  box-sizing: border-box;
  border: 1px solid #990263;
  &::before {
    content: "";
    position: absolute;
    width: 448px;
    height: 100%;
    z-index: 1;
    background: #990263;
    right: 0;
    top: 0;
  }
  .left {
    width: 275px;
    position: relative;
    z-index: 2;
    .z_head {
      box-sizing: border-box;
      padding-left: 100px;
      border-bottom:1px solid rgba(255,255,255,0.3);
      .header-left {
        .vertical-line {
          background-color: #fff;
        }
        div {
          &:nth-child(2) {
            color: #fff;
          }
        }
      }
    }
  }
  .right {
    flex: 1;
    width: 0;
    height: 100%;
    background: #fff;
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    padding: 21px 31px 18px 25px;
  }
  .more {
    position: absolute;
    right: 31px;
    bottom: 18px;
    font-size: 14px;
    color: #b6b6b6;
    cursor: pointer;
    span {
      color: #990263;
    }
  }
}

.z_nav1 {
  width: 100%;
  height: 545px;
  overflow: auto;
  &::-webkit-scrollbar-thumb {
    background: rgba(153, 153, 153, 0.5);
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  .a {
    cursor: pointer;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 16px;
    color: #fff;
    padding-left: 100px;
    position: relative;
    &::before{content: "";position: absolute;width: calc(100% - 100px);right: 0;bottom: 0;height: 1px;background: rgba(255, 255, 255, 0.1);}
    &.active {
      background: rgba(255, 255, 255, 0.1);
    }
  }
}

.z_zhinan1 {
  width: 100%;
  margin: 0;
  padding: 0;
  li {
    width: 100%;
    list-style-type: none;
    height: 50px;
    font-size: 16px;
    color: #010101;
    background: #fafafc;
    display: flex;
    align-items: center;
    border-radius: 40px;
    box-sizing: border-box;
    padding-left: 28px;
    padding-right: 35px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .h1 {
      flex: 1;
      margin-right: 35px;
      width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
    .h2 {
      width: auto;
      white-space: nowrap;
    }
  }
}

.z_wenzhang4 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .li {
    width: calc(50% - 10px);
    height: 393px;
    position: relative;
    box-sizing: border-box;
    padding: 25px;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: #fff;
      left: 0;
      top: 0;
    }
    &::after {
      content: "";
      width: calc(100% - 20px);
      position: absolute;
      z-index: 1;
      bottom: -10px;
      height: 23px;
      background: #9702a3;
    }
    &:nth-child(1) {
      &::after {
        right: 0;
        border-bottom-left-radius: 20px;
      }
    }
    &:nth-child(2) {
      &::after {
        left: 0;
        border-bottom-right-radius: 20px;
      }
    }
    .z_head {
      position: relative;
      z-index: 3;
      .sxright {
        display: flex;
        align-items: center;
        font-size: 17px;
        color: #9a9a9a;
        cursor: pointer;
        img {
          margin-right: 17px;
          width: 20px;
        }
      }
    }
  }
}

.z_zhinan2 {
  width: 100%;
  margin: 0;
  padding: 0;
  padding-top: 30px;
  position: relative;
  z-index: 3;
  li {
    width: 100%;
    list-style-type: none;
    font-size: 16px;
    color: #010101;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    .h1 {
      flex: 1;
      margin-right: 35px;
      width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
    .h2 {
      width: auto;
      white-space: nowrap;
    }
  }
}
::v-deep .typeArea .bottom {
  display: none;
}
</style>